<script setup lang="ts">
import type { Schemas } from "#shopware";

const props = defineProps<{
  heading?: string;
  categories: Array<Schemas["Category"]>;
}>();
const { heading, categories } = toRefs(props);
</script>

<template>
  <div>
    <div
      v-if="props.heading !== undefined"
      class="text-[24px] c-scheppach-primary-700 font-700 mb-2"
    >
      {{ heading }}
    </div>

    <div
      class="grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6"
    >
      <ProductCategoryCard
        v-for="category in categories"
        :key="category.id"
        :category="category"
      />
    </div>
  </div>
</template>
